import React from "react";

import styles from "./cta-button.module.css";
import { Icon } from "semantic-ui-react";

const CTAButton = ({ text, url }) => (
  <a className={styles.ctaButton} href={url}>
    {text} <Icon name="right arrow" />
  </a>
);

export default CTAButton;
