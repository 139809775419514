import React from "react";
import { Header, Container } from "semantic-ui-react";

import styles from "./heading-image.module.css";
import BackgroundImage from "../background-image";

const HeadingImage = ({ fileName, h1, h2 }) => (
  <BackgroundImage fileName={fileName} className={styles.backgroundImage}>
    <Container fluid textAlign="center" className={styles.container}>
      <Header
        as="h1"
        content={h1}
        inverted
        style={{
          fontSize: "3.5em",
          fontWeight: "bold",
          paddingBottom: 0,
          paddingTop: 0,
          marginBottom: "0px",
          textTransform: "uppercase"
        }}
      />
      <Header
        as="h2"
        content={h2}
        inverted
        style={{
          fontWeight: "normal",
          marginBottom: "0px",
          paddingTop: 0,
          marginBlockStart: 0,
          textTransform: "uppercase"
        }}
      />
    </Container>
  </BackgroundImage>
);

export default HeadingImage;
