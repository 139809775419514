import React from "react";

import styles from "./content-area-feature-list-parents.module.css";

const ContentAreaFeatureListParents = ({}) => (
  <div className={styles.contentAreaWrapper}>
    <h2>KEYNOTES</h2>
    <ul>
      <li className={styles.alternateRow}>
        Learn guitar, bass, drums, keyboards or vocals. No experience is
        necessary
      </li>
      <li>½ hour band practice every week supported by pro musicians</li>
      <li className={styles.alternateRow}>
        Learn by playing your favourite songs, and writing your own
      </li>
      <li>The opportunity to record your music or play gigs every term</li>
      <li className={styles.alternateRow}>
        All instruments and equipment are provided.
      </li>
      <li>Up your musical and band skills for an affordable price</li>
    </ul>
  </div>
);

export default ContentAreaFeatureListParents;
