import React from "react";
import { Segment } from "semantic-ui-react";
import styles from "./styled-content-image.module.css";
import Image from "../optimized-image";

const StyledContentImage = ({
  filename,
  alt,
  floated,
  width,
  clipStyle,
  marginBottom
}) => (
  <Segment
    basic
    floated={floated || "right"}
    style={{
      width: width || "200px",
      marginBottom: marginBottom || "auto"
    }}
    className={styles[clipStyle || "right"]}
  >
    <Image filename={filename} alt={alt} />
  </Segment>
);

export default StyledContentImage;
