import React from "react";
import { Container, Grid, Icon } from "semantic-ui-react";

import LayoutHome from "../../components/layout/layout-home";
import SEO from "../../components/seo/seo";
import SecondaryNavBar from "../../components/secondary-nav-bar/secondary-nav-bar";
import HeadingImage from "../../components/heading-image/heading-image";
import StyledContentImage from "../../components/styled-content-image/styled-content-image";
import ContentAreaPrimary from "../../components/content-area-primary/content-area-primary";
import ContentAreaFeatureListParents from "../../components/content-area-feature-list-parents/content-area-feature-list-parents";
import CTAButton from "../../components/cta-button";

const Page = () => (
  <LayoutHome>
    <SEO title="Secondary" keywords={[`rocksteady`]} />

    <Container>
      <SecondaryNavBar
        product="secondary"
        title="Rocksteady Secondary"
        links={[
          {
            name: "For Students / Parents",
            path: "/secondary/students-and-parents/",
            active: true
          },
          {
            name: "For Schools",
            path: "/secondary/schools/",
            active: false
          }
        ]}
        cta={{
          text: "Enrol Today",
          href: "https://booking.rocksteadymusicschool.com/"
        }}
      />
    </Container>
    <HeadingImage
      fileName="parents/banner_bg.jpg"
      h1="Rocksteady Secondary"
      h2="For Students / Parents"
    />
    <Container>
      <Grid stackable columns={2}>
        <Grid.Column width={10}>
          <ContentAreaPrimary>
            <h2>PLAY YOUR PART</h2>

            <p>
              Choose from guitar, bass, drums, keyboard or vocals and we’ll help
              you to form (or join) a band, where you’ll learn how to play your
              instrument. Don't worry if you don't know many people who play,
              everyone is welcome and we will make sure you are together with
              likeminded musicians.
            </p>

            <StyledContentImage filename="parents/photo_1.jpg" />

            <h2>YOUR BAND, YOUR CHOICE</h2>

            <p>
              Whether you’re just getting started or already a pro, the best way
              to improve is learning to play songs by the artists you’re already
              listening to. Rocksteady Band Leaders are up to date on every
              music scene imaginable and can help you cover songs in a way that
              suits you and your band.
            </p>

            <h2>A SPACE TO CREATE</h2>

            <p>
              Writing your own material is one of the best parts of being in a
              band. At rehearsals you’ll learn how to write lyrics, melodies,
              chords, riffs, beats and turn them into a sound that’s original
              and unmistakeably your band. When it’s ready to share with the
              world, you’ll learn how to record it and get it out for others to
              hear.
            </p>

            <StyledContentImage
              filename="parents/photo_2.jpg"
              floated="left"
              clipStyle="left"
            />

            <h2>PLAY LIVE AND LOUD</h2>

            <p>
              When you’ve put in the hours practicing together, there’s nothing
              more rewarding than sharing your music with other people. You’ll
              have the opportunity to play termly gigs to friends, family and a
              supportive audience who love live music.
            </p>

            <h2>FOOTNOTES </h2>

            <p>
              Rehearsals are half an hour and take place every week during
              school time. Sessions are supported by a Rocksteady Band Leader,
              who are all professional gigging musicians, highly trained in the
              art of passing on their skills to others. All instruments are
              provided so no need to splash out on your own before you’ve tried
              and it's also a lot more affordable than learning one to one.
            </p>

            <h2>Enrol</h2>

            <Grid stackable columns={2}>
              <Grid.Column>
                Lessons are booked on a first come first served basis. The
                quickest and easiest way to enrol is via our online booking
                system. (Because Rocksteady Secondary is brand new, it’s the
                same one we use for primary schools but don’t worry, it will
                still work for you too.)
                <CTAButton
                  url="https://booking.rocksteadymusicschool.com"
                  text="Enrol Today"
                />
              </Grid.Column>
              <Grid.Column textAlign="center">
                <h5>If you have any questions please call us on:</h5>

                <h3 style={{ marginTop: "0px" }}>0330 113 0330</h3>
                <p>(Monday-Friday, 9am-5pm)</p>
              </Grid.Column>
            </Grid>
          </ContentAreaPrimary>
        </Grid.Column>
        <Grid.Column width={6}>
          <ContentAreaFeatureListParents />
          <StyledContentImage
            filename="parents/photo_3.jpg"
            floated="right"
            clipStyle="left"
            width="90%"
            marginBottom="40px"
          />
        </Grid.Column>
      </Grid>
    </Container>
  </LayoutHome>
);

export default Page;
